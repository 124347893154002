<script setup>
import Button from '@/Components/Button/Button.vue';
import { inject } from 'vue';

import { useForm } from '@inertiajs/vue3';

// Icons
import { mapErrors } from '@/utilities';
import saveIcon from '@i/save-white.svg';

const route = inject('route');
const form = useForm({
    current_password: '',
    password: '',
    password_confirmation: '',
});

const submitForm = () => {
    form.put(route('user-password.update'), {
        preserveState: true,
        preserveScroll: true,
        errorBag: 'updatePassword',
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess: () => form.reset(),
    });
};
</script>

<template>
    <FormKit
        type="password"
        name="current_password"
        :label="$t('Current {attribute}', { attribute: 'password' })"
        v-model="form.current_password"
        :errors="form.errors.current_password"
        :value="form.current_password"
    />
    <FormKit
        type="password"
        name="password"
        :label="$t('New {attribute}', { attribute: 'password' })"
        v-model="form.password"
        :errors="form.errors.password"
        :value="form.password"
    />
    <FormKit
        type="password"
        name="password_confirmation"
        :label="$t('Repeat {attribute}', { attribute: 'password' })"
        v-model="form.password_confirmation"
        :errors="form.errors.password_confirmation"
        :value="form.password_confirmation"
    />
    <div class="flex items-center justify-end w-full">
        <div class="">
            <Button
                type="button"
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :text="$t('Save')"
                :icon="saveIcon"
                @click="submitForm"
            />
        </div>
    </div>
</template>
