<script setup>
import { mapErrors } from '@/utilities';
import { Switch } from '@headlessui/vue';
import { Link, useForm, usePage, router } from '@inertiajs/vue3';
import { computed, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Button from '@/Components/Button/Button.vue';
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';

// Icons
import saveIcon from '@i/save-white.svg';

// Partials
import UpdatePasswordFormFields from '@/Components/Form/UpdatePasswordFormFields.vue';

import UserSingleSettingBar from '../../../../Components/Form/FormSingleSettingBar.vue';
import TinyUserInfoCard from './TinyUserInfoCard.vue';
import UserInfoFormFields from './UserInfoFormFields.vue';

const route = inject('route');
const setDayjsLocale = inject('setDayjsLocale');
const setZiggyLocale = inject('setZiggyLocale');
const i18n = useI18n();
const { t } = useI18n();

const props = defineProps({
    companies: Array,
    user: Object,
});

const form = useForm({
    ...props.user,
    current_password: null,
    password: null,
    password_confirmation: null,
    email_verification_code: '',
    phone_number_verification_code: '',
});

const sendForm = () => {
    form.transform((data) => {
        if (data.current_password == null) {
            delete data.current_password;
        }

        if (data.password == null) {
            delete data.password;
        }

        if (data.password_confirmation == null) {
            delete data.password_confirmation;
        }

        return data;
    }).put(
        route('location.profile.update', {
            profile: props.user.id,
        }),
        {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors, (error) => [error])),
            onSuccess: () => {
                i18n.locale.value = form.locale;
                setDayjsLocale(form.locale);
                setZiggyLocale(form.locale);
                router.get(
                    usePage().props.route.locale[form.locale],
                    {},
                    { preserveState: true, preserveScroll: true }
                );
            },
        }
    );
};

const hasCompany = computed(() => props.companies.length != 0);
const hasAnyLocations = computed(() => usePage().props.teams != 0);
const isProbablyTeamMember = computed(() => usePage().props.is_probably_team_member);

const warningMessage = computed(() => {
    if (!hasAnyLocations.value) {
        if (isProbablyTeamMember.value) {
            return t(
                'You currently don’t have any active locations. Please contact your company administrator or support to solve this issue.'
            );
        }

        return t(
            'Before you are able to post a shift at Flex@ble you would have to create a company and add a location first.'
        );
    }

    return null;
});

const changePassword = ref(false);
</script>

<template>
    <div
        v-if="warningMessage"
        class="flex items-center justify-center w-full py-4 text-2xl font-bold leading-tight text-center text-white rounded min-h-10 bg-pink lg:px-32"
    >
        {{ warningMessage }}
    </div>

    <TinyUserInfoCard :user="user" v-if="user" />

    <FormKit type="form" id="user-update-form" :actions="false">
        <UserInfoFormFields :form="form" />

        <div>
            <UserSingleSettingBar hasTwoColumns :title="$t('Notification settings')">
                <span>{{ $t('E-mail') }}</span>

                <Switch
                    v-model="form.has_email_notification"
                    :class="[
                        form.has_email_notification ? 'bg-blue' : 'bg-blue/30',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2',
                    ]"
                >
                    <span class="sr-only">Use setting</span>
                    <span
                        aria-hidden="true"
                        :class="[
                            form.has_email_notification ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        ]"
                    />
                </Switch>
            </UserSingleSettingBar>

            <UserSingleSettingBar hasTwoColumns class="border-b border-blue/30">
                <span>{{ $t('Phone') }}</span>

                <Switch
                    v-model="form.has_phone_notification"
                    :class="[
                        form.has_phone_notification ? 'bg-blue' : 'bg-blue/30',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2',
                    ]"
                >
                    <span class="sr-only">Use setting</span>
                    <span
                        aria-hidden="true"
                        :class="[
                            form.has_phone_notification ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        ]"
                    />
                </Switch>
            </UserSingleSettingBar>
        </div>

        <div class="flex gap-6 mt-8 mb-4">
            <div class="flex items-end justify-end w-full">
                <Button
                    type="button"
                    :processing="form.processing"
                    :recentlySuccessful="form.recentlySuccessful"
                    :text="$t('Save')"
                    :icon="saveIcon"
                    class="w-fit"
                    @click="sendForm"
                />
            </div>
        </div>

        <div class="flex flex-col gap-6 mb-4">
            <div>
                <UserSingleSettingBar :title="$t('Security')" hasThreeColumns>
                    <div class="col-span-3 mb-4" v-if="changePassword">
                        <UpdatePasswordFormFields />
                    </div>

                    <span v-if="!changePassword">{{ $t('Password') }}</span>
                    <span v-if="!changePassword">******</span>
                    <button
                        type="button"
                        class="font-bold text-right transition-colors duration-300 text-blue-light hover:text-blue justify-self-end"
                        :class="{ 'col-span-3': changePassword }"
                        v-html="!changePassword ? $t('Change') : $t('Cancel')"
                        @click="changePassword = !changePassword"
                    ></button>
                </UserSingleSettingBar>

                <UserSingleSettingBar
                    hasThreeColumns
                    class="border-b border-blue/30"
                    v-if="user.two_factor_confirmed_at"
                >
                    <span>{{ $t('2-factor authentication') }}</span>
                    <span>{{ $t('Enabled') }}</span>
                    <Link
                        as="button"
                        type="button"
                        method="delete"
                        :href="route('user.two-factor.destroy')"
                        class="font-semibold text-right transition-colors duration-300 text-blue-light hover:text-blue"
                        >{{ $t('Reset') }}</Link
                    >
                </UserSingleSettingBar>

                <UserSingleSettingBar hasThreeColumns class="border-b border-blue/30" v-else>
                    <span>{{ $t('2-factor authentication') }}</span>
                    <span>{{ $t('Disabled') }}, This is not possible in production sites</span>
                </UserSingleSettingBar>
            </div>

            <div>
                <FormSingleSettingBar :title="$t('Companies')" class="mt-12" removeInnerPadding v-if="companies">
                    <div
                        v-for="company in companies"
                        :key="company.id"
                        class="flex items-center justify-between border-b text-blue border-blue/30 min-h-13"
                    >
                        <span>{{ company.commercial_name }}</span>

                        <Link
                            class="transition-all duration-300 cursor-pointer hover:pr-2"
                            :href="route('location.companies.show', { company: company.id })"
                        >
                            <img
                                class="w-[8px] h-auto float-right"
                                src="@/../img/icon/pagination-arrow.svg"
                                :alt="$t('Link')"
                            />
                        </Link>
                    </div>
                </FormSingleSettingBar>
                <Link
                    :href="route('location.companies.create')"
                    class="float-right mt-4 text-base font-semibold text-blue-light"
                >
                    {{ $t('Add Company') }}
                </Link>
            </div>
        </div>
    </FormKit>
</template>
